import React from "react";
import { useNavigate } from "react-router-dom";
import {
  assessmentApi,
  authApi,
  chatApi,
  checkinApi,
  learnApi,
  planApi,
  progressApi,
  referralApi,
  userApi,
  getUser,
  logout,
  useAppDispatch,
  useAppSelector,
} from "shared-redux";
import { t, theme } from "shared-utilities";
import styled from "styled-components/macro";
import { useWindowSize } from "usehooks-ts";
import SmallButton from "../components/buttons/SmallButton";
import Paragraph from "../components/texts/Paragraph";

const STATIC_ROWS = [
  { text: t("profile_personal_information"), route: "PersonalInformation" },
  { text: t("profile_change_pw"), route: "ChangePassword" },
];

interface RowItem {
  text: string;
  route?: string;
  action?: () => void;
}

const RowSeparator = styled.div`
  height: 2px;
  background-color: ${theme.palette.LIGHTER_GREY};
`;

const Root = styled.div<{ height: number }>`
  flex: 1;
  display: flex;
  height: calc(${(props) => `${props.height}px` || "100vh"} - 120px);
`;
export const DashboardProfileScreen: React.FC<React.PropsWithChildren> = () => {
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  // Store
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);

  const handleLogOut = async () => {
    await Promise.all(
      [
        logout(),
        assessmentApi.util.resetApiState(),
        authApi.util.resetApiState(),
        chatApi.util.resetApiState(),
        checkinApi.util.resetApiState(),
        learnApi.util.resetApiState(),
        planApi.util.resetApiState(),
        progressApi.util.resetApiState(),
        referralApi.util.resetApiState(),
        userApi.util.resetApiState(),
      ].map(dispatch)
    );
    navigate("/");
    // navigation.navigate("DashboardWorkout");
  };

  // Renderers
  const renderBuildInfo = () => {
    const buildInfo = `Version 0.0.1`; // TODO get from package.json
    return (
      <Paragraph size="small" color={theme.palette.PRIMARY} text={buildInfo} />
    );
  };

  const renderForm = () => {
    if (!user) return null;
    const loggedInAs = `${t("profile_logged_in_as")} ${user?.email ?? ""}`;
    // console.log('schedule', workoutSchedule);
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        {/* <Paragraph
          size="small"
          color={theme.palette.PRIMARY}
          text={loggedInAs}
          style={{ marginBottom: theme.spacing(0.5) }}
        /> */}
        {renderBuildInfo()}
        <div style={{ padding: theme.spacing(2) }}>
          {/* TODO replace with RoundButton */}
          {/* <RoundButton */}
          {/*  text={t("btn_logout")} */}
          {/*  backgroundColor={theme.palette.BACKGROUND} */}
          {/*  textColor={theme.palette.PRIMARY} */}
          {/*  onPress={handleLogOut} */}
          {/*  // trackingEvent="Logout" */}
          {/* /> */}
          <SmallButton
            text={t("btn_logout")}
            onPress={handleLogOut}
            backgroundColor={theme.palette.BACKGROUND}
            textColor={theme.palette.PRIMARY}
            style={{ paddingTop: 10, paddingBottom: 10, borderRadius: 50 }}
          />
        </div>
      </div>
    );
  };

  return <Root height={height}>{renderForm()}</Root>;
};
